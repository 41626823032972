@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}


/* Style the header: fixed position (always stay at the top) */
.header {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  background-color: #f1f1f1;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar {
    box-shadow: 0px 3px 10px #d7d7d7;
    position: relative;
}
.nav-custom .nav-link.active, .nav-custom .show>.nav-link {
    /*background-size: 2px 1px;*/
    /*background-color: orange;*/
    color: #8BD4C0;
    text-decoration: none;
}
#text {
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    font-weight:600;
    transition: 0.3s;
    color: #434343;
}
#text.active {
    color: #8BD4C0;
    font-weight: 700;
}
#text:hover {
    color: #8BD4C0;
}
.sticky-nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
/*.nav-pills > .active > a, .nav-pills > .active > a:hover{*/
/*    background-color: orange ;*/
/*}*/


/*.nav-pills .nav-link.active, .nav-pills .show>.nav-link {*/
/*    color: #fff;*/
/*    background-color: orange;*/
/*}*/


.heading {
    font-family: "Open Sans", sans-serif;
    font-weight:700;
    font-size:35px;
    text-align: left;
    /*justify-content:center;*/
    /*align-items:center;*/
    color: #434343;
}
.intro {
    /* min-height: 100vh; */
    padding: 0 0.5rem;
    display: -webkit-flex;
    display: flex;
    /* flex-direction: column; */
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.projects {
    /*background-color: orange;*/
    /*padding: 0 0.5rem;*/
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: start;
            align-items: start;
}

:root {
    --mainColor: #8BD4C0;
}

.pagelink {
        background:
                linear-gradient(
                        to bottom, #8BD4C0 0%,
                        #8BD4C0 100%
                );
        background:
                linear-gradient(
                        to bottom, var(--mainColor) 0%,
                        var(--mainColor) 100%
                );
        background-position: 0 100%;
        background-repeat: repeat-x;
        background-size: 4px 4px;
        color: #434343;
        text-decoration: none;
        transition: background-size .4s;
}

.pagelink:hover {
    background-size: 2px 15px;
    color: #434343;
    text-decoration: none;
}
.introwords {
    font-family: "Open Sans", sans-serif;
    font-size:20px;
    font-weight:600;
    text-align: left;
    color: #434343;
    padding: 0 0.5rem;
}
.photo {
    width: 33%;
    margin-left: 2%;
}
.filter{
    width: 50%;
    right: 10%;
    /*margin-left: 2%;*/
    text-align: right;
    padding: 0 1rem;
    font-family: "Open Sans", sans-serif;
    font-size:20px;
    font-weight:600;
    color: #434343;
    /*sticky things*/
    position: sticky;
    position: -webkit-sticky;
    /*background: #f83d23;*/
    top: 170px;
    /*justify-content: center;*/
    /*align-items: center;*/
    }

.filter ul {
    list-style: none;
    /*direction: rtl;*/
    /*list-style-position: outside;*/
}
@media (max-width: 700px) {
    .intro {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 90% !important;
    }
    .photo {
        width: 80% !important;
        margin-left: 10%;
        /*display: none;*/
    }
}
.bullet {
    text-decoration: none;
}
.cardTitle{
    font-family: "Open Sans", sans-serif;
    font-weight:600;
    font-size:25px;
    text-align: left;
    /*justify-content:center;*/
    /*align-items:center;*/
    color: #434343;
    margin-bottom: 0rem;
}
.cardText {
    text-align: left;
}
.badge-software{
    background-color: white;
    border: 2px solid #EEA09F;
    color: #EEA09F;
}
.badge-engineering {
    background-color: white;
    border: 2px solid #edb271;
    color: #edb271;
}
.badge-product {
    background-color: white;
    border: 2px solid #ecdc67;
    color: #ecdc67;
}
.badge-design {
    background-color: white;
    border: 2px solid #87d872;
    color: #87d872;
}
.badge-dei {
    background-color: white;
    border: 2px solid #8DD0F3;
    color: #8DD0F3;
}
.badge-music {
    background-color: white;
    border: 2px solid #AC9AF6;
    color: #AC9AF6;
}
.card{
    border-radius: 20px;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    /*padding: 14px 80px 18px 36px;*/
    /*cursor: pointer;*/
    margin-bottom: 5rem;
}

.stretched-link{
    /*display: none;*/
}
.card:hover{
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.card-deck{
    width: 60%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /*margin-right: 3rem;*/
    position: relative;
    left: 15%;
    /*background-color: green;*/
}
.horizontal {
    display: inline;
    margin: 8px;
    font-size: 18px;
    color: #434343;
    /* margin-top: 0px; */
}
.icons{
    display: inline;
    color: #434343;
    transition: 0.3s;
}
.noHighlight {
    color: #434343;
    transition: 0.3s;
}

.icons:hover {
    /*background-color: white;*/
    color: #8BD4C0;
}

.horizontal:hover {
    cursor: pointer;
    text-decoration: none;
}

.list {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 0px;
    padding-left: 0px;
}
.list:hover {
    text-decoration: none;
}
p:hover {
    text-decoration: none;
}

.footer {
    position: center;
    bottom: 0;
    /* width: 100%; */
    height: 2.5rem;
    margin-bottom: 4%;
    margin-left: 4%;
    text-decoration: none;
}
.jack {
    width: 15%;
    height: 15%;
    text-align: right;
}

@media (max-width: 480px) {
    .footer {
        margin-bottom: 3rem;
    }
    .footerText {
        font-size: 14px;
    }
}

.footerText {
    margin-left: 0.5%;
    font-style: italic;
    width: 100%;
}
.logos:hover {
    text-decoration: none;
}
