@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;800&display=swap");
.cardTitle{
    font-family: "Open Sans", sans-serif;
    font-weight:600;
    font-size:25px;
    text-align: left;
    /*justify-content:center;*/
    /*align-items:center;*/
    color: #434343;
    margin-bottom: 0rem;
}
.cardText {
    text-align: left;
}
.badge-software{
    background-color: white;
    border: 2px solid #EEA09F;
    color: #EEA09F;
}
.badge-engineering {
    background-color: white;
    border: 2px solid #edb271;
    color: #edb271;
}
.badge-product {
    background-color: white;
    border: 2px solid #ecdc67;
    color: #ecdc67;
}
.badge-design {
    background-color: white;
    border: 2px solid #87d872;
    color: #87d872;
}
.badge-dei {
    background-color: white;
    border: 2px solid #8DD0F3;
    color: #8DD0F3;
}
.badge-music {
    background-color: white;
    border: 2px solid #AC9AF6;
    color: #AC9AF6;
}
.card{
    border-radius: 20px;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    /*padding: 14px 80px 18px 36px;*/
    /*cursor: pointer;*/
    margin-bottom: 5rem;
}

.stretched-link{
    /*display: none;*/
}
.card:hover{
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.card-deck{
    width: 60%;
    display: flex;
    flex-direction: column;
    /*margin-right: 3rem;*/
    position: relative;
    left: 15%;
    /*background-color: green;*/
}