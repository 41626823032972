.navbar {
    box-shadow: 0px 3px 10px #d7d7d7;
    position: relative;
}
.nav-custom .nav-link.active, .nav-custom .show>.nav-link {
    /*background-size: 2px 1px;*/
    /*background-color: orange;*/
    color: #8BD4C0;
    text-decoration: none;
}
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
#text {
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    font-weight:600;
    transition: 0.3s;
    color: #434343;
}
#text.active {
    color: #8BD4C0;
    font-weight: 700;
}
#text:hover {
    color: #8BD4C0;
}
.sticky-nav {
    position: sticky;
    top: 0;
}
/*.nav-pills > .active > a, .nav-pills > .active > a:hover{*/
/*    background-color: orange ;*/
/*}*/


/*.nav-pills .nav-link.active, .nav-pills .show>.nav-link {*/
/*    color: #fff;*/
/*    background-color: orange;*/
/*}*/

